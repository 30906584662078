export default class extends ApplicationController {
    static targets = [ "all", "checkbox" ]
    static values = {type: String}

    connect() {
        console.log("[SELECT_ALL] Connected");
        this.allTarget.addEventListener("change", this.toggle.bind(this));

    }

    toggle(e) {
        console.log("[SELECT_ALL] Toggling", e.target.checked);
        this.checkboxTargets.forEach((checkbox) => {
             if (checkbox.checked !== e.target.checked) checkbox.click()
        });
    }

}
